import { getUserId, removeAuthToken } from "@/utils/authToken";
import { useCallback, useMemo } from "react";

const useUser = () => {
  const isConected = useMemo(() => Boolean(getUserId()), [getUserId]);
  const userId = useMemo(() => getUserId(), [getUserId]);

  const logout = useCallback(() => {
    removeAuthToken();
  }, []);

  return { isConected, userId, logout };
};

export default useUser;
